import Storage from './storage';

let instance = null;

const storage = new Storage();

const clone = o => JSON.parse(JSON.stringify(o))

class State {
    constructor(init = {}) {
        if (!instance) instance = this;
        this.state = init
        instance = this;
        return instance;
    }
    clean() {
        const agora = new Date();
        Object.keys(this.state).forEach(key => {
            if (new Date(this.state[key].ttl) < agora) delete(this.state[key])
        })
    }
    has(key) {
        this.clean();
        if (key in this.state) return true;
        if (storage.has(key)) {
            this.state[key] = {
                data: storage.get(key),
                ttl: storage.getTtl(key)
            };
            return this.state[key].data
        }
        return key in this.state
    }
    get(key) {
        this.clean()
        if (key in this.state) return clone(this.state[key].data);
        if (storage.has(key)) {
            this.state[key] = {
                data: storage.get(key),
                ttl: storage.getTtl(key)
            };
            return clone(this.state[key].data)
        }
        return null
    }
    set(key, value, ttl) {
        this.clean()
        if (!value) return
        const newState = {}
        newState[key] = {
            data: value,
            ttl: new Date().getTime() + ttl
        }
        this.state = {
            ...this.state,
            ...newState
        }
        try {
            storage.set(key, value, ttl);
        } catch {
            return false
        }
    }
    del(key) {
        delete(this.state[key]);
        storage.del(key)
    }

}

export default State