import Vue from "vue";

let instance = null;

class Storage {

  constructor() {
    if (!instance) instance = this;
    return instance
  }

  clean() {
    const agora = new Date();
    Object.keys(localStorage)
      .filter(key => /^app_.*$/.test(key))
      .forEach(key => {
        const value = JSON.parse(localStorage[key]);
        if (agora > new Date(value.ttl)) localStorage.removeItem(key);
      });
  }

  getTtl(key) {
    if (!this.has(key)) return null;
    return JSON.parse(localStorage.getItem(`app_${key}`)).ttl
  }

  get(key) {
    this.clean();
    if (Vue.$storage.has(key)) return Vue.$storage.get(key);
  }

  set(key, value, ttl) {
    this.clean();
    if (!value) return;
    Vue.$storage.set(key, value, {
      ttl
    });
  }

  del(key) {
    Vue.$storage.remove(key)
  }

  has(key) {
    this.clean();
    return Vue.$storage.has(key)
  }

}

export default Storage;