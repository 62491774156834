import { fetch } from "../helpers/rest";

export const get = () => {
  return fetch("indicador/operacao/grupo", 24 * 7 * 60);
};

export const getProdutos = () => {
  return fetch("produto/indicador", 0);
};

export const getProdutosDosIndicadores = indicadores => {
  return fetch(`/indicador/produtos/${indicadores}/`, 60);
};

export const getTodosProdutoEmIndicadores = () => {
  const url = `/indicador/produtosNosIndicadores`;
  return fetch(url, 10);
};

/*se não passar o produto puxa tudo*/
export const getProdutoTodos = () => {
  const url = `/produto`;
  return fetch(url, 10);
};

export const getProduto = produto => {
  const url = `/produto/${produto}`;
  return fetch(url, 10);
};
