<template>
  <v-container>
    <v-card elevation="2" outlined>
      <v-card-title>Registrar usando QrCode</v-card-title>
      <v-card-text>
        <v-btn
          class="ml-8"
          elevation="2"
          color="primary"
          @click="marcarEntradaQrCode"
          >marcar Entrada</v-btn
        >
        <v-btn
          class="ml-8"
          elevation="2"
          color="yellow"
          @click="chamarCriancaQrCode"
          >Chamar criança</v-btn
        >
        <v-btn
          class="ml-8"
          elevation="2"
          color="orange"
          @click="chamarCriancaGrupoQrCode"
          >Chamar Grupo</v-btn
        >
        <v-btn
          class="ml-8"
          elevation="2"
          color="green"
          @click="registrarSaidaQrCode"
          >registrar Saída</v-btn
        >
      </v-card-text>
    </v-card>
    <v-card elevation="2" outlined>
      <v-card-title>Chamada</v-card-title>
      <v-card-text>
        <v-btn
          class="ml-8"
          elevation="2"
          color="primary"
          @click="$router.push('telaChamada')"
          >Abrir tela chamada</v-btn
        >
      </v-card-text>
    </v-card>
    <v-card elevation="2" outlined>
      <v-card-title>Dashboard</v-card-title>
      <v-card-text>
        <div>
          <v-btn tile>dentro: {{ this.quantidadesSituacao.entrou }}</v-btn>
          <v-btn tile>chamando: {{ this.quantidadesSituacao.chamando }}</v-btn>
          <v-btn tile>saiu: {{ this.quantidadesSituacao.saiu }}</v-btn>
        </div>
        <div class="mt-2">
          <v-btn tile
            >Só cadastrou: {{ this.quantidadesSituacao.cadastrado }}</v-btn
          >
          <v-btn tile
            >primeira vez hoje: {{ this.quantidadesSituacao.vemHoje }}</v-btn
          >
          <v-btn tile
            >veio só outro dia: {{ this.quantidadesSituacao.veioOutroDia }}</v-btn
          >
        </div>
        

        <div class="mt-2">
          <v-btn tile>PREVISTO / REALIZADO DIA {{ dia1 }} </v-btn>
          <v-btn tile color="green lighten-3"
            >Até quatro:
            <span
              v-if="this.quantidadesSituacao.zeroQuatroRealizadaDia1"
              class="realizado"
            >
              {{ this.quantidadesSituacao.zeroQuatroRealizadaDia1 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.zeroQuatroPrevistoDia1 }}
            </span>
          </v-btn>
          <v-btn tile color="blue lighten-3"
            >Cinco e seis:
            <span
              v-if="this.quantidadesSituacao.cincoSeisRealizadaDia1"
              class="realizado"
            >
              {{ this.quantidadesSituacao.cincoSeisRealizadaDia1 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.cincoSeisPrevistoDia1 }}
            </span>
          </v-btn>
          <v-btn tile color="yellow lighten-3">
            Sete e oito:
            <span
              v-if="this.quantidadesSituacao.seteOitoRealizadaDia1"
              class="realizado"
            >
              {{ this.quantidadesSituacao.seteOitoRealizadaDia1 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.seteOitoPrevistoDia1 }}
            </span>
          </v-btn>
          <v-btn tile color="red lighten-3"
            >Nove ~ doze:
            <span
              v-if="this.quantidadesSituacao.noveDezOnzeRealizadaDia1"
              class="realizado"
            >
              {{ this.quantidadesSituacao.noveDezOnzeRealizadaDia1 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.noveDezOnzePrevistoDia1 }}
            </span>
          </v-btn>
          <v-btn tile color="gray lighten-4">
            <span
              v-if="this.quantidadesSituacao.realizadoDia1"
              class="realizado"
            >
              {{ this.quantidadesSituacao.realizadoDia1 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.dia1 }}
            </span></v-btn
          >
        </div>
        <div class="mt-2">
          <v-btn tile>PREVISTO / REALIZADO DIA {{ dia2 }} </v-btn>
          <v-btn tile color="green lighten-3"
            >Até quatro:
            <span
              v-if="this.quantidadesSituacao.zeroQuatroRealizadaDia2"
              class="realizado"
            >
              {{ this.quantidadesSituacao.zeroQuatroRealizadaDia2 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.zeroQuatroPrevistoDia2 }}
            </span>
          </v-btn>
          <v-btn tile color="blue lighten-3"
            >Cinco e seis:
            <span
              v-if="this.quantidadesSituacao.cincoSeisRealizadaDia2"
              class="realizado"
            >
              {{ this.quantidadesSituacao.cincoSeisRealizadaDia2 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.cincoSeisPrevistoDia2 }}
            </span>
          </v-btn>
          <v-btn tile color="yellow lighten-3">
            Sete e oito:
            <span
              v-if="this.quantidadesSituacao.seteOitoRealizadaDia2"
              class="realizado"
            >
              {{ this.quantidadesSituacao.seteOitoRealizadaDia2 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.seteOitoPrevistoDia2 }}
            </span>
          </v-btn>
          <v-btn tile color="red lighten-3"
            >Nove ~ dez:
            <span
              v-if="this.quantidadesSituacao.noveDezRealizadaDia2"
              class="realizado"
            >
              {{ this.quantidadesSituacao.noveDezRealizadaDia2 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.noveDezPrevistoDia2 }}
            </span>
          </v-btn>
          <v-btn tile color="#FF8C00"
            >Onze ~ doze:
            <span
              v-if="this.quantidadesSituacao.onzeDozeRealizadaDia2"
              class="realizado"
            >
              {{ this.quantidadesSituacao.onzeDozeRealizadaDia2 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.onzeDozePrevistoDia2 }}
            </span>
          </v-btn>
          <v-btn tile color="gray lighten-4">
            <span
              v-if="this.quantidadesSituacao.realizadoDia2"
              class="realizado"
            >
              {{ this.quantidadesSituacao.realizadoDia2 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.dia2 }}
            </span></v-btn
          >
        </div>
        <div class="mt-2">
          <v-btn tile>PREVISTO / REALIZADO DIA {{ dia3 }} </v-btn>
          <v-btn tile color="green lighten-3"
            >Até quatro:
            <span
              v-if="this.quantidadesSituacao.zeroQuatroRealizadaDia3"
              class="realizado"
            >
              {{ this.quantidadesSituacao.zeroQuatroRealizadaDia3 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.zeroQuatroPrevistoDia3 }}
            </span>
          </v-btn>
          <v-btn tile color="blue lighten-3"
            >Cinco e seis:
            <span
              v-if="this.quantidadesSituacao.cincoSeisRealizadaDia3"
              class="realizado"
            >
              {{ this.quantidadesSituacao.cincoSeisRealizadaDia3 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.cincoSeisPrevistoDia3 }}
            </span>
          </v-btn>
          <v-btn tile color="yellow lighten-3">
            Sete e oito:
            <span
              v-if="this.quantidadesSituacao.seteOitoRealizadaDia3"
              class="realizado"
            >
              {{ this.quantidadesSituacao.seteOitoRealizadaDia3 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.seteOitoPrevistoDia3 }}
            </span>
          </v-btn>
          <v-btn tile color="red lighten-3"
            >Nove ~ dez:
            <span
              v-if="this.quantidadesSituacao.noveDezRealizadaDia3"
              class="realizado"
            >
              {{ this.quantidadesSituacao.noveDezRealizadaDia3 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.noveDezPrevistoDia3 }}
            </span>
          </v-btn>
          <v-btn tile color="#FF8C00"
            >Onze ~ doze:
            <span
              v-if="this.quantidadesSituacao.onzeDozeRealizadaDia3"
              class="realizado"
            >
              {{ this.quantidadesSituacao.onzeDozeRealizadaDia3 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.onzeDozePrevistoDia3 }}
            </span>
          </v-btn>
          <v-btn tile color="gray lighten-4">
            <span
              v-if="this.quantidadesSituacao.realizadoDia3"
              class="realizado"
            >
              {{ this.quantidadesSituacao.realizadoDia3 }}
              <span class="previsto">/</span>
            </span>
            <span class="previsto">
              {{ this.quantidadesSituacao.dia3 }}
            </span></v-btn
          >
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation="2" outlined>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          :loading="loading"
          item-key="rowNumber"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Cadastros</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer
              ><v-btn color="primary" class="mb-2" text @click="initialize"
                >Atualizar</v-btn
              >
              <v-dialog v-model="dialog" max-width="500px">
                <!--template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Item
                  </v-btn>
                </template-->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.co_codigo"
                            label="Código"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_nome"
                            label="Criança"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_equipe"
                            label="Equipe"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_responsavel"
                            label="Responsáveis"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_telefone_responsavel1"
                            label="Telefone 1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_telefone_responsavel2"
                            label="Telefone 2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_alergia"
                            label="Alergia"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.no_igreja"
                            label="Igreja"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogMarcarEntrada" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Marcar entrada e imprimir etiqueta?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="closeMarcarEntrada"
                      >Cancelar</v-btn
                    >
                    <v-btn color="green" @click="marcarEntradaConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogChamarCrianca" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Chamar criança?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="closeChamarCrianca"
                      >Cancelar</v-btn
                    >
                    <v-btn color="green" large @click="chamarCriancaConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogChamarCriancaGrupo" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Chamar Grupo?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="closeChamarCriancaGrupo"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="green"
                      large
                      @click="chamarCriancaGrupoConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogRegistrarSaida" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Registrar saída?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="closeRegistrarSaida"
                      >Cancelar</v-btn
                    >
                    <v-btn color="green" large @click="registrarSaidaConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogReimprimirEtiqueta" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Reimprimir etiqueta?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="closeReimprimirEtiqueta"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="green"
                      large
                      @click="reimprimirEtiquetaConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogMarcarEntradaQrCode" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Marcar entrada com QrCode</v-card-title
                  >
                  <v-card-text
                    ><QrCodeReader tipoLeitura="entrada"
                  /></v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeMarcarEntradaQrCode"
                      >Fechar</v-btn
                    >

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogChamarCriancaQrCode" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Chamar com QrCode</v-card-title
                  >
                  <v-card-text
                    ><QrCodeReader tipoLeitura="chamar"
                  /></v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeChamarCriancaQrCode"
                      >Fechar</v-btn
                    >

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogChamarCriancaGrupoQrCode"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Chamar EM GRUPO com QrCode</v-card-title
                  >
                  <v-card-text
                    ><QrCodeReader tipoLeitura="chamarEmGrupo"
                  /></v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeChamarCriancaGrupoQrCode"
                      >Fechar</v-btn
                    >

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogRegistrarSaidaQrCode" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Registrar saída com QrCode</v-card-title
                  >
                  <v-card-text
                    ><QrCodeReader tipoLeitura="saida"
                  /></v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeRegistrarSaidaQrCode"
                      >Fechar</v-btn
                    >

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogVincularCodigo" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center"
                    >Vincular código com QrCode</v-card-title
                  >
                  <v-card-text
                    ><QrCodeReader
                      tipoLeitura="vincular"
                      :editedItem="editedItem"
                  /></v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeVincularCodigo"
                      >Fechar</v-btn
                    >

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.no_nome`]="{ item }">
            <div class="futurabold">{{ item.no_nome }}</div>
          </template>
          <template v-slot:[`item.ts_inscricao`]="{ item }">
            <div>{{ item.ts_inscricao | moment("DD/MM HH:mm") }}</div>
          </template>
          <template v-slot:[`item.no_telefone_responsavel1`]="{ item }">
            <div>
              {{
                item.no_telefone_responsavel1 +
                " " +
                item.no_telefone_responsavel2
              }}
            </div>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.actions="{ item }">
            <!--v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon-->
            <v-btn
              v-if="item.co_codigo && item.situacao != 'entrou'"
              small
              elevation="2"
              color="primary"
              @click="marcarEntrada(item)"
              >marcar Entrada</v-btn
            >
            <v-btn
              v-if="item.situacao == 'entrou'"
              small
              elevation="2"
              color="yellow"
              @click="chamarCrianca(item)"
              >Chamar criança</v-btn
            >
            <v-btn
              v-if="item.situacao == 'entrou'"
              small
              elevation="2"
              color="orange"
              @click="chamarCriancaGrupo(item)"
              >Chamar em Grupo</v-btn
            >
            <v-btn
              v-if="item.co_codigo && item.situacao != 'saiu'"
              small
              elevation="2"
              color="green"
              large
              @click="registrarSaida(item)"
              >registrar Saída</v-btn
            >
            <v-btn
              v-if="!item.co_codigo"
              small
              elevation="2"
              color="orange"
              @click="vincularCodigo(item)"
              >vincular código</v-btn
            >
            <v-btn small elevation="2" color="gray" @click="editItem(item)"
              >editar</v-btn
            >
            <v-btn
              v-if="item.co_codigo"
              small
              elevation="2"
              color="red"
              @click="reimprimirEtiqueta(item)"
              >reimprimir etiqueta</v-btn
            >
          </template>
          <template v-slot:no-data>
            <!--v-btn color="primary" @click="initialize"> Reset </v-btn-->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import QrCodeReader from "@/components/QrCodeReader.vue";
import moment from "moment";

import {
  getListaCriancas,
  setCrianca,
  setRegistro,
} from "../services/cadastroCriancas.js";

export default {
  data: () => ({
    dia1: "11",
    dia2: "12",
    dia3: "13",
    dia4: "14",
    loading: false,
    atualizarAutomaticamente: true,
    timer: "",
    dialog: false,
    dialogDelete: false,
    dialogMarcarEntrada: false,
    dialogMarcarEntradaQrCode: false,
    dialogChamarCrianca: false,
    dialogChamarCriancaGrupo: false,
    dialogChamarCriancaQrCode: false,
    dialogChamarCriancaGrupoQrCode: false,
    dialogRegistrarSaida: false,
    dialogRegistrarSaidaQrCode: false,
    dialogVincularCodigo: false,
    dialogReimprimirEtiqueta: false,
    quantidadesSituacao: {},
    search: "",
    headers: [
      //{ text: "#", value: "rowNumber" },
      { text: "Código", value: "co_codigo" },
      { text: "Data da Inscrição", value: "ts_inscricao" },
      { text: "Nome", value: "no_nome", width: "15%" },
      { text: "Idade", value: "nu_idade" },
      //{ text: "Data de nascimento", value: "data_nascimento" },
      { text: "Responsavel", value: "no_responsavel" },
      { text: "Telefone1", value: "no_telefone_responsavel1" },
      //{ text: "Telefone2", value: "no_telefone_responsavel2" },
      { text: "Igreja", value: "no_igreja" },
      //{ text: "Dias", value: "no_dias" },
      { text: "Alergia", value: "no_alergia" },
      { text: "Imagem", value: "ic_autoriza_uso_imagem" },
      { text: "Situação", value: "situacao" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  components: {
    QrCodeReader,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo" : "Editar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.timer = setInterval(() => {
      if (this.atualizarAutomaticamente) this.initialize();
    }, 5000);
  },

  methods: {
    initialize() {
      this.loading = true;
      getListaCriancas().then((res) => {
        this.desserts = res.data;
        this.quantidadesSituacao.vinculado = this.desserts.filter((item) => {
          return item.situacao == "vinculado";
        }).length;
        this.quantidadesSituacao.veioOutroDia = this.desserts.filter((item) => {
          return item.situacao == "veio outro dia";
        }).length;
        this.quantidadesSituacao.cadastrado = this.desserts.filter((item) => {
          return item.situacao == "cadastrado";
        }).length;
        this.quantidadesSituacao.vemHoje = this.desserts.filter((item) => {
          return (
            item.situacao == "cadastrado" &&
            item.no_dias.includes(moment().format("DD")) &&
            !item.no_dias.includes(moment().subtract(1, "days").toDate())
          );
        }).length;
        this.quantidadesSituacao.entrou = this.desserts.filter((item) => {
          return item.situacao == "entrou";
        }).length;
        this.quantidadesSituacao.chamando = this.desserts.filter((item) => {
          return item.situacao == "chamando";
        }).length;
        this.quantidadesSituacao.saiu = this.desserts.filter((item) => {
          return item.situacao == "saiu";
        }).length;

        //prevista dia 1
        this.quantidadesSituacao.zeroQuatroPrevistoDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 0 ||
                item.nu_idade == 1 ||
                item.nu_idade == 2 ||
                item.nu_idade == 3 ||
                item.nu_idade == 4) &&
              item.no_dias.includes(this.dia1)
            );
          }
        ).length;

        this.quantidadesSituacao.cincoSeisPrevistoDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 5 || item.nu_idade == 6) &&
              item.no_dias.includes(this.dia1)
            );
          }
        ).length;

        this.quantidadesSituacao.seteOitoPrevistoDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 7 || item.nu_idade == 8) &&
              item.no_dias.includes(this.dia1)
            );
          }
        ).length;
        this.quantidadesSituacao.noveDezOnzePrevistoDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 9 ||
                item.nu_idade == 10 ||
                item.nu_idade == 11 ||
                item.nu_idade == 12) &&
              item.no_dias.includes(this.dia1)
            );
          }
        ).length;

        //prevista dia 2
        this.quantidadesSituacao.zeroQuatroPrevistoDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 0 ||
                item.nu_idade == 1 ||
                item.nu_idade == 2 ||
                item.nu_idade == 3 ||
                item.nu_idade == 4) &&
              item.no_dias.includes(this.dia2)
            );
          }
        ).length;

        this.quantidadesSituacao.cincoSeisPrevistoDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 5 || item.nu_idade == 6) &&
              item.no_dias.includes(this.dia2)
            );
          }
        ).length;

        this.quantidadesSituacao.seteOitoPrevistoDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 7 || item.nu_idade == 8) &&
              item.no_dias.includes(this.dia2)
            );
          }
        ).length;
        this.quantidadesSituacao.noveDezPrevistoDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 9 || item.nu_idade == 10) &&
              item.no_dias.includes(this.dia2)
            );
          }
        ).length;
        this.quantidadesSituacao.onzeDozePrevistoDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 11 || item.nu_idade == 12) &&
              item.no_dias.includes(this.dia2)
            );
          }
        ).length;

        //prevista dia 3
        this.quantidadesSituacao.zeroQuatroPrevistoDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 0 ||
                item.nu_idade == 1 ||
                item.nu_idade == 2 ||
                item.nu_idade == 3 ||
                item.nu_idade == 4) &&
              item.no_dias.includes(this.dia3)
            );
          }
        ).length;

        this.quantidadesSituacao.cincoSeisPrevistoDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 5 || item.nu_idade == 6) &&
              item.no_dias.includes(this.dia3)
            );
          }
        ).length;

        this.quantidadesSituacao.seteOitoPrevistoDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 7 || item.nu_idade == 8) &&
              item.no_dias.includes(this.dia3)
            );
          }
        ).length;
        this.quantidadesSituacao.noveDezPrevistoDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 9 || item.nu_idade == 10) &&
              item.no_dias.includes(this.dia3)
            );
          }
        ).length;
        this.quantidadesSituacao.onzeDozePrevistoDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 11 || item.nu_idade == 12) &&
              item.no_dias.includes(this.dia3)
            );
          }
        ).length;

        //realizado total do dia
        this.quantidadesSituacao.realizadoDia1 = this.desserts.filter(
          (item) => {
            return item.ts_entrada_dia1 !== null;
          }
        ).length;

        this.quantidadesSituacao.realizadoDia2 = this.desserts.filter(
          (item) => {
            return item.ts_entrada_dia2 !== null;
          }
        ).length;

        this.quantidadesSituacao.realizadoDia3 = this.desserts.filter(
          (item) => {
            return item.ts_entrada_dia3 !== null;
          }
        ).length;

        //prevista total do dia
        this.quantidadesSituacao.dia1 = this.desserts.filter((item) => {
          return item.no_dias.includes(this.dia1);
        }).length;

        this.quantidadesSituacao.dia2 = this.desserts.filter((item) => {
          return item.no_dias.includes(this.dia2);
        }).length;

        this.quantidadesSituacao.dia3 = this.desserts.filter((item) => {
          return item.no_dias.includes(this.dia3);
        }).length;

        //realizada hoje
        /*this.quantidadesSituacao.zeroQuatro = this.desserts.filter((item) => {
          return (
            (item.nu_idade == 0 ||
              item.nu_idade == 1 ||
              item.nu_idade == 2 ||
              item.nu_idade == 3 ||
              item.nu_idade == 4) &&
            item.ts_entrada !== null
          );
        }).length;
        this.quantidadesSituacao.cincoSeis = this.desserts.filter((item) => {
          return (
            (item.nu_idade == 5 || item.nu_idade == 6) &&
            item.ts_entrada !== null
          );
        }).length;
        this.quantidadesSituacao.seteOito = this.desserts.filter((item) => {
          return (
            (item.nu_idade == 7 || item.nu_idade == 8) &&
            item.ts_entrada !== null
          );
        }).length;
        this.quantidadesSituacao.noveDezOnze = this.desserts.filter((item) => {
          return (
            (item.nu_idade == 9 ||
              item.nu_idade == 10 ||
              item.nu_idade == 11 ||
              item.nu_idade == 12) &&
            item.ts_entrada !== null
          );
        }).length;
        */
        //realizada dia1
        this.quantidadesSituacao.zeroQuatroRealizadaDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 0 ||
                item.nu_idade == 1 ||
                item.nu_idade == 2 ||
                item.nu_idade == 3 ||
                item.nu_idade == 4) &&
              item.ts_entrada_dia1 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.cincoSeisRealizadaDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 5 || item.nu_idade == 6) &&
              item.ts_entrada_dia1 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.seteOitoRealizadaDia1 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 7 || item.nu_idade == 8) &&
              item.ts_entrada_dia1 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.noveDezOnzeRealizadaDia1 =
          this.desserts.filter((item) => {
            return (
              (item.nu_idade == 9 ||
                item.nu_idade == 10 ||
                item.nu_idade == 11 ||
                item.nu_idade == 12 ||
                item.nu_idade == 13) &&
              item.ts_entrada_dia1 !== null
            );
          }).length;
        //realizada dia2
        this.quantidadesSituacao.zeroQuatroRealizadaDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 0 ||
                item.nu_idade == 1 ||
                item.nu_idade == 2 ||
                item.nu_idade == 3 ||
                item.nu_idade == 4) &&
              item.ts_entrada_dia2 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.cincoSeisRealizadaDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 5 || item.nu_idade == 6) &&
              item.ts_entrada_dia2 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.seteOitoRealizadaDia2 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 7 || item.nu_idade == 8) &&
              item.ts_entrada_dia2 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.noveDezRealizadaDia2 =
          this.desserts.filter((item) => {
            return (
              (item.nu_idade == 9 ||
                item.nu_idade == 10) &&
              item.ts_entrada_dia2 !== null
            );
          }).length;
        this.quantidadesSituacao.onzeDozeRealizadaDia2 =
          this.desserts.filter((item) => {
            return (
              (item.nu_idade == 11 ||
                item.nu_idade == 12 ||
                item.nu_idade == 13) &&
              item.ts_entrada_dia2 !== null
            );
          }).length;
        //realizada dia3
        this.quantidadesSituacao.zeroQuatroRealizadaDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 0 ||
                item.nu_idade == 1 ||
                item.nu_idade == 2 ||
                item.nu_idade == 3 ||
                item.nu_idade == 4) &&
              item.ts_saida_dia3 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.cincoSeisRealizadaDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 5 || item.nu_idade == 6) &&
              item.ts_saida_dia3 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.seteOitoRealizadaDia3 = this.desserts.filter(
          (item) => {
            return (
              (item.nu_idade == 7 || item.nu_idade == 8) &&
              item.ts_saida_dia3 !== null
            );
          }
        ).length;
        this.quantidadesSituacao.noveDezOnzeRealizadaDia3 =
          this.desserts.filter((item) => {
            return (
              (item.nu_idade == 9 ||
                item.nu_idade == 10 ||
                item.nu_idade == 11 ||
                item.nu_idade == 12 ||
                item.nu_idade == 13) &&
              item.ts_saida_dia3 !== null
            );
          }).length;
        this.loading = false;
      });
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

    beforeDestroy() {
      this.cancelAutoUpdate();
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedrowNumber = item.rowNumber;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }

      // salvar
      setCrianca(this.editedItem.id, this.editedItem)
        .then((res) => {
          if (res.status == 201) {
            this.close();
          }
        })
        .then(() => {
          this.initialize();
        });

      this.loading = false;
    },

    marcarEntrada(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogMarcarEntrada = true;
    },
    marcarEntradaQrCode() {
      this.dialogMarcarEntradaQrCode = true;
    },
    marcarEntradaConfirm() {
      this.loading = true;

      // salvar no gs
      setRegistro(this.editedItem.id, 2) //entrada
        .then((res) => {
          if (res.status < 300) {
            this.closeMarcarEntrada();
          }
        })
        .then(() => {
          this.initialize();
        });

      this.loading = false;
    },

    closeMarcarEntrada() {
      this.dialogMarcarEntrada = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeMarcarEntradaQrCode() {
      this.dialogMarcarEntradaQrCode = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    chamarCriancaQrCode() {
      this.dialogChamarCriancaQrCode = true;
    },
    closeChamarCriancaQrCode() {
      this.dialogChamarCriancaQrCode = false;
    },
    chamarCriancaGrupoQrCode() {
      this.dialogChamarCriancaGrupoQrCode = true;
    },
    closeChamarCriancaGrupoQrCode() {
      this.dialogChamarCriancaGrupoQrCode = false;
    },
    chamarCrianca(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogChamarCrianca = true;
    },
    chamarCriancaConfirm() {
      this.loading = true;

      // salvar no gs
      setRegistro(this.editedItem.id, 3) //chamada
        .then((res) => {
          if (res.status < 300) {
            this.closeChamarCrianca();
          }
        })
        .then(() => {
          this.initialize();
        });

      this.loading = false;
    },
    closeChamarCrianca() {
      this.dialogChamarCrianca = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    chamarCriancaGrupo(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogChamarCriancaGrupo = true;
    },
    chamarCriancaGrupoConfirm() {
      this.loading = true;

      // salvar no gs
      setRegistro(this.editedItem.id, 5) //chamada em grupo
        .then((res) => {
          if (res.status < 300) {
            this.closeChamarCriancaGrupo();
          }
        })
        .then(() => {
          this.initialize();
        });

      this.loading = false;
    },
    closeChamarCriancaGrupo() {
      this.dialogChamarCriancaGrupo = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    registrarSaidaQrCode() {
      this.dialogRegistrarSaidaQrCode = true;
    },
    closeRegistrarSaidaQrCode() {
      this.dialogRegistrarSaidaQrCode = false;
    },
    registrarSaida(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRegistrarSaida = true;
    },
    registrarSaidaConfirm() {
      this.loading = true;

      // salvar no gs
      setRegistro(this.editedItem.id, 4) //saida
        .then((res) => {
          if (res.status < 300) {
            this.closeRegistrarSaida();
          }
        })
        .then(() => {
          this.initialize();
        });

      this.loading = false;
    },
    closeRegistrarSaida() {
      this.dialogRegistrarSaida = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    vincularCodigo(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogVincularCodigo = true;
    },
    vincularCodigoConfirm() {
      //this.desserts.splice(this.editedIndex, 1);
      this.closeVincularCodigo();
    },
    closeVincularCodigo() {
      this.dialogVincularCodigo = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.initialize();
    },

    reimprimirEtiqueta(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReimprimirEtiqueta = true;
    },
    reimprimirEtiquetaConfirm() {
      this.loading = true;

      // salvar no gs
      setRegistro(this.editedItem.id, 6) // reimprimir etiqueta
        .then((res) => {
          if (res.status < 300) {
            this.closeReimprimirEtiqueta();
          }
        })
        .then(() => {
          this.initialize();
        });

      this.loading = false;
    },

    closeReimprimirEtiqueta() {
      this.dialogReimprimirEtiqueta = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.initialize();
    },
  },
};
</script>

<style scoped>
.previsto {
  font-size: 12px;
}
.realizado {
  font-weight: 700;
}
</style>