import { fetch } from "../helpers/rest";

import State from "../helpers/state";

const state = new State();

export const login = async payload => {
  const matricula = "c" + ("000000" + payload.matricula).slice(-6);
  const userToken = btoa(`${matricula}:${payload.senha}`);
  const token = `Basic ${userToken}`;
  const options = {
    headers: {
      Authorization: token
    }
  };
  const data = await fetch("autenticacao/login", 0, options);
  const funcionario = data.funcionario;
  const jwt = data.token;
  funcionario.unidade = parseInt(funcionario.unidade.slice(-4));
  state.del("temp");
  state.set("usuario", funcionario, 1000 * 60 * 60 * 12);
  state.set("token", jwt, 1000 * 60 * 60 * 12);
  return funcionario;
};

export const logoff = () => {
  state.del("usuario");
  state.del("token");
};

export const getToken = () => state.get("token");

export const getUsuario = () => state.get("usuario");
