<template>
  <div class="home">
    <ModeloQrCodes msg="Bem vindo a EBF"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ModeloQrCodes from '@/components/ModeloQrCodes.vue'

export default {
  name: 'ModeloQrCodesView',
  components: {
    ModeloQrCodes
  }
}
</script>
