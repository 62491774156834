import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import CountryFlag from "vue-country-flag";
import VueAnalytics from "vue-analytics";
import VueQr from "vue-qr";
import store from "./store/index";
import { Plugin } from "vue2-storage";
import "./plugins/axios";


Vue.component("country-flag", CountryFlag);
Vue.config.productionTip = false;
import "./assets/css/novo-layout.css";

Vue.component("VueQr", VueQr);
Vue.use(require('vue-moment'));

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: "UA-232214793-1",
  router,
});

Vue.config.productionTip = false;
Vue.use(Plugin, {
  prefix: "app_",
  driver: "local",
  ttl: 60 * 5 * 1000,
});

new Vue({
  vuetify,
  router,
  store,
  Plugin,
  render: (h) => h(App),
}).$mount("#app");
