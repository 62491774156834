<template>
  <div>
    <!-- 
      A4: 210x297
      210/54 = 3,89  largura
      297/85 = 3,49  altura
      
      5 e 6       = azul
      7 e 8       = vermelho
      9, 10 e 11  = laranja
     -->
    <table>
      <tr>
        <td style="display: grid; grid-template-columns: repeat(5, 1fr)">
          <!-- <v-card
            elevation="0"
            min-width="5.4cm"
            max-width="5.4cm"
            height="8,5cm"
            v-for="(item, i) in verso"
            :key="i"
          >
            <div class="semquebra">
              <table>
                <tr>
                  <td>
                    <h5>DIAS 13, 14 E 15 DE JULHO DAS: 13:30 ÀS 17:30 DIA 17 ENCERRAMENTO DAS: 10H40 ÀS 12H NÃO ESQUEÇA DE CONFIRMAR A ENTRADA E A SAÍDA DA SUA CRIANÇA NA FRENTE DO TEMPLO. GUARDE BEM ESTE CARTÃO ATÉ O FINAL DA EBF!</h5>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center">
                    <vue-qr
                      margin="3"
                      :text="'' + item.codigo"
                      :size="150"
                    ></vue-qr>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{{ item.codigo }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      border: 1px dotted black;
                      border-radius: 10px;
                      height: 45px;
                    "
                  >
                  </td>
                </tr>
              </table>
            </div>
            <div
              v-if="[19, 39, 59, 79, 99, 119, 139, 159, 179, 199].includes(i)"
              class="pagebreak"
            ></div>
          </v-card> -->

          <v-card
            elevation="0"
            min-width="5.4cm"
            max-width="5.4cm"
            height="8,5cm"
            v-for="(item, i) in codigos5"
            :key="i"
          >
            <div class="semquebra">
              <table>
                <tr>
                  <td>
                    <img
                      alt="LOGO"
                      width="100%"
                      src="../assets/cabeçalho_site_ebf2024.jpg"
                    />
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center">
                    <vue-qr
                      margin="3"
                      :text="'' + item.codigo"
                      :size="150"
                    ></vue-qr>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{{ item.codigo }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      border: 1px dotted black;
                      border-radius: 10px;
                      height: 45px;
                    "
                  >
                    VOLUNTÁRIO
                  </td>
                </tr>
              </table>
            </div>
            <div
              v-if="[19, 39, 59, 79, 99, 119, 139, 159, 179, 199].includes(i)"
              class="pagebreak"
            ></div>
          </v-card>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// https://openbase.com/js/vue-qr

export default {
  data: () => ({
    src2: "favicon.ico",
    src: "",
    //items: codigos,
  }),
  computed: {
    codigos5: function () {
      var items = [];
      var startNumber = 9901;
      var endNumber = 9990;
      for (let index = startNumber; index <= endNumber; index++) {
        items.push({ codigo: index, nome: "ebf" });
      }
      return items;
    },
    verso: function () {
      var items = [];
      var startNumber = 0;
      var endNumber = 19;
      for (let index = startNumber; index <= endNumber; index++) {
        items.push({ codigo: index });
      }
      return items;
    },
  },
};
</script>

<style scoped>
@media print {
  .semquebra {
    display: inline-block;
    page-break-inside: avoid;
    width: 100%;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
</style>