<template>
  <div>
    <p v-if="!editedItem" class="decode-result">
      <span v-if="result">
        Última {{ tipoLeitura }} lida: <b>{{ result }}</b></span>
        <!-- {{ msg }} -->
      <v-alert type="success" v-if="affectedRows > 0">Sucesso </v-alert>
      <v-alert type="error" v-if="affectedRows == 0">Falha</v-alert>
    </p>
    <p v-if="editedItem" class="decode-result">
      <span>{{ editedItem.no_nome }}</span>
    </p>
    <div v-if="msg" class="futurabold">{{ msg }}</div>
    <qrcode-stream v-if="showReader" :camera="camera" @decode="onDecode" @init="onInit">
      <div v-if="validationSuccess" class="validation-success">
        <v-card v-if="!editedItem">
          <v-card-title v-if="crianca" class="text-h5">{{
            crianca.no_nome
          }}</v-card-title>
          <v-card-text>
            <v-progress-circular
              v-if="loading"
              :size="100"
              indeterminate
              class="azul_caixa"
            ></v-progress-circular
            >Código: {{ this.result }} <br />
            <div v-if="crianca">Responsável:</div>
            <div v-if="crianca" class="futurabold">
              {{ crianca.no_responsavel }}
            </div>
            <div v-if="msg" class="futurabold">{{ msg }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="cancelRead">Cancelar</v-btn>
            <v-btn
              v-if="crianca"
              :disabled="loading"
              color="green"
              large
              @click="marcarConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

        <v-card v-if="editedItem">
          <v-card-title v-if="crianca" class="text-h5">{{
            editedItem.no_nome
          }}</v-card-title>
          <v-card-text
            >Código: <span class="futurabold">{{ this.result }}</span>
            <div v-if="msg" class="futurabold">{{ msg }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="cancelRead">Cancelar</v-btn>
            <v-btn
              v-if="!crianca"
              :disabled="loading"
              color="yellow darken-3"
              text
              @click="marcarConfirm"
              >VINCULAR</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </div>

      <div v-if="validationFailure" class="validation-failure">
        Código não reconhecido
      </div>

      <div v-if="validationPending" class="validation-pending">
        Validando...
      </div>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { getCrianca, setRegistro } from "../services/cadastroCriancas.js";

export default {
  components: { QrcodeStream },
  props: {
    tipoLeitura: String,
    editedItem: Object,
  },
  data() {
    return {
      isValid: undefined,
      camera: "auto",
      result: null,
      crianca: [],
      loading: false,
      msg: null,
      affectedRows: null,
      showReader: true,
    };
  },

  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      return this.isValid === false;
    },
  },

  methods: {
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      this.msg = null;
      this.crianca = null;
      // pretend it's taking really long
      await this.timeout(500);
      this.isValid = content.length > 3 ? true : false;

      await this.getDadosCrianca(this.result);

      // some more delay, so users have time to read the message
      await this.timeout(120000);

      this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    cancelRead() {
      this.resetValidationState();
      this.turnCameraOn();
      this.crianca = [];
      //this.msg = null;
      this.affectedRows = null;
      this.loading = false;
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    async getDadosCrianca(codigo) {
      this.loading = true;
      await getCrianca(codigo).then((res) => {
        this.crianca = res.data[0];
        if (res.rows == 0) {
          //NADA
          this.msg = "Código sem vínculo";
          this.crianca = null;
          this.affectedRows = null;
        }
        this.loading = false;
      });
    },

    marcarConfirm() {
      this.loading = true;
      let tipo = null;
      let idCodigo = { id: null, codigo: this.result };

      if (this.editedItem == null) idCodigo.id = this.crianca.id;
      else idCodigo.id = this.editedItem.id;

      let msgSucesso = "Atualizado com sucesso.";
      switch (this.tipoLeitura) {
        case "vincular":
          tipo = 1;
          msgSucesso = "Vinculado com sucesso.";
          break;
        case "entrada":
          tipo = 2;
          msgSucesso = "Entrou com sucesso.";
          break;
        case "chamar":
          tipo = 3;
          msgSucesso = "Chamou com sucesso.";
          break;
        case "saida":
          tipo = 4;
          msgSucesso = "Saiu com sucesso.";
          break;
        case "chamarEmGrupo":
          tipo = 5;
          msgSucesso = "Chamou em grupo com sucesso.";
          break;

        default:
          break;
      }
      setRegistro(idCodigo.id, tipo, idCodigo.codigo).then((res) => {
        if (res.data.data.affectedRows > 0){
          this.msg = msgSucesso;
          this.tipoLeitura == 'vincular' ? this.showReader = false : this.showReader = true;
          }
        else 
          this.msg = "Falha: " + res.data.data.affectedRows + " atualizado.";

        this.affectedRows = res.data.data.affectedRows;
        this.cancelRead();
      });
    },
  },
};
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>