<template>
  <div class="home">
    <!--img alt="Despertar 22" width="40%" src="../assets/Logo Despertar 22.jpg"-->
    <!--img alt="EBF" width="100%" md=2 src="../assets/logo_ebf_verde_pequena_forms.png"-->
    <HomeRegistration msg="Bem vindo à Escola Bíblica de Férias da Cruz Verde"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeRegistration from '@/components/HomeRegistration.vue'

export default {
  name: 'HomeView',
  components: {
    HomeRegistration
  }
}
</script>
