import {
  get as getMascaras
} from '../../services/mascaras';
export default {
  state: {
    lista: [],
    loading: false,
    error: false,
  },
  actions: {
    get: async ({
      commit
    }) => {
      commit("SET_LISTA_MASCARAS", []);
      commit("SET_LOADING_MASCARAS", true);
      getMascaras().then(data => {
        commit("SET_LISTA_MASCARAS", data);
        commit("SET_LOADING_MASCARAS", false);

      })
    },
  },
  mutations: {
    SET_LISTA_MASCARAS: (state, payload) => (state.lista = Object.freeze(payload)),
    SET_LOADING_MASCARAS: (state, payload) => (state.loading = payload)
  },
  getters: {
    lista: state => state.lista,
  }
};