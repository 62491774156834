"use strict";

import Vue from "vue";
import axios from "axios";
import router from "../router";

import { getToken } from "../services/autenticacao";

let config = {
  baseURL:
    process.env.VUE_APP_BASEURL ||
    process.env.baseURL ||
    process.env.apiUrl ||
    process.env.url ||
    "http://localhost:3000/api/"
    //"https://ebf.ieimb.com.br:3000/api/"
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  config => {
    const sessionToken = getToken();
    if (!sessionToken) {
      Vue.$storage.remove("token");
      Vue.$storage.remove(`tll_token`);
      Vue.$storage.remove("usuario");
    } else {
      config.headers.Authorization = `Bearer ${sessionToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    //let errorMsg = error.message || null;
    let errorCode = (error.response || {}).status || null;
    /*if (errorMsg == "Network Error") {
      return router.push({
        name: "erro500"
      });
    }*/

    switch (errorCode) {
      case 403:
        return router.push({
          name: "erro403"
        });
      case 401:
        Vue.$storage.remove("token");
        Vue.$storage.remove(`tll_token`);
        Vue.$storage.remove("usuario");
        return router.push({
          name: "erro401"
        });
      case 404:
        return router.push({
          name: "erro404"
        });
      /*case 500:
        return router.push({
          name: "erro500"
        });*/
      default:
        return Promise.reject(error);
    }
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
