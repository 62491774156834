import uuid from "uuid";
export default {
  state: {
    lista: [],
    loading: false
  },
  actions: {
    get: ({ commit }) => {
      commit("SET_CONTESTACOES_LISTA", []);
      commit("SET_CONTESTACOES_LOADING", true);
      let situacoes = [
        { id: uuid(), nome: "ANÁLISE SR" },
        { id: uuid(), nome: "ANÁLISE GEGAD" },
        { id: uuid(), nome: "PAUTA SUGAD" },
        { id: uuid(), nome: "DEFERIDO" },
        { id: uuid(), nome: "INDEFERIDO" }
      ];
      let quantidade = Math.ceil(Math.random() * (30 - 10)) + 10;
      let contestacoes = [];
      let unidade = Math.round(Math.random() * 4999 + 1);
      for (let i = 1; i <= quantidade; i++) {
        contestacoes.push({
          id: uuid(),
          unidade,
          nomeUnidade: "xxxxxxxxxxxxx",
          contrato: Math.round(
            Math.random() * (999999999 - 111111111) + 111111111
          ),
          nomeCliente: "xxxxxxxxx xxxxxxxxx xxxxxxxxxx",
          operacao: Math.round(Math.random() * (9999 - 1000) + 1000),
          nomeOperacao: "xxxxxxxxxx",
          situacao:
            situacoes[Math.round(Math.random() * (situacoes.length - 1))],
          posicao: new Date(),
          arquivos: [],
          sistema: "xxxxx"
        });
      }
      setTimeout(() => {
        commit("SET_CONTESTACOES_LISTA", contestacoes);
        commit("SET_CONTESTACOES_LOADING", false);
      }, 500);
    }
  },
  mutations: {
    SET_CONTESTACOES_LISTA: (state, payload) => (state.lista = payload),
    SET_CONTESTACOES_LOADING: (state, payload) => (state.loading = payload),
    ADD_CONTESTACAO: (state, payload) => state.contestacoes.unshift(payload)
  },
  getters: {
    lista: state =>
      state.lista.map(c => {
        return {
          unidade: ("0000" + c.unidade).slice(-4) + " " + c.nomeUnidade,
          operacao: ("0000" + c.operacao).slice(-4) + " " + c.nomeOperacao,
          contrato: c.contrato,
          situacao: c.situacao,
          posicao: c.posicao,
          cliente: c.nomeCliente
        };
      }),
    loading: state => state.loading
  }
};
