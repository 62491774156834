import { get as getUnidades } from "../../services/unidades";

import Vue from "vue";
export default {
  state: {
    lista: [],
    loading: false,
    unidade: null
  },
  actions: {
    update: async ({ commit }) => {
      commit("SET_LOADING_UNIDADES", true);
      const lista = await getUnidades();
      commit(
        "SET_LISTA_UNIDADES",
        lista
          .map(u => {
            u.NO_LISTA =
              ("0000" + u.NU_UNIDADE).slice(-4) +
              " " +
              (u.SG_UNIDADE ? u.SG_UNIDADE + " - " : "") +
              " " +
              u.NO_UNIDADE;
            return u;
          })
          .sort((a, b) => (a.NU_UNIDADE < b.NU_UNIDADE ? -1 : a.NU_UNIDADE > b.NU_UNIDADE ? 1 : 0))
      );
      commit("SET_LOADING_UNIDADES", false);
    },
    setUnidade: ({ commit }, cgc = "a") => {
      if (isNaN(cgc)) return false;
      commit("SET_UNIDADE", parseInt(cgc));
      Vue.$storage.set("unidadeUsuario", cgc, {
        ttl: 1000 * 60 * 60 * 24 * 7
      });
    }
  },
  mutations: {
    SET_LISTA_UNIDADES: (state, payload) => {
      state.lista = Object.freeze(payload);
    },
    SET_LOADING_UNIDADES: (state, payload) => {
      state.loading = Object.freeze(payload);
    },
    SET_UNIDADE: (state, payload) => {
      state.unidade = payload;
    }
  },
  getters: {
    cadeia: state => cgc => {
      if (!cgc) return [];
      if (!state.lista.length) return [];
      let cadeia = [];
      let unidade = state.lista.filter(u => u.NU_UNIDADE == cgc)[0];
      cadeia.unshift(unidade);
      while (unidade.NU_UNIDADE != 5802) {
        unidade = state.lista.filter(u => u.NU_UNIDADE == unidade.NU_SUBORDINADORA)[0];
        cadeia.unshift(unidade);
      }
      return cadeia;
    },
    lista: state => {
      if (!Array.isArray(state.lista)) return [];
      return state.lista;
    },
    loading: state => state.loading,
    unidade: state => state.unidade
  }
};
