<template>
  <div class="home">
    <TelaChamada msg="Chamada"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TelaChamada from '@/components/TelaChamada.vue'

export default {
  name: 'TelaChamadaView',
  components: {
    TelaChamada
  }
}
</script>
