<template>
  <v-container style="max-width: 100%">
    <v-card class="mt-5" elevation="2" outlined>
      <v-card-text>
        <v-row>
          <v-col>
            <v-switch
              v-model="atualizarAutomaticamente"
              :label="'Atualizar automaticamente'"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <!--v-col class="ml-n4 blue" style="padding: 4px">
            <v-data-table
              :headers="headers"
              :items="criancasCinco"
              item-key="id"
              sort-by="dataChamada"
              class="elevation-1"
              :loading="loading"
              loading-text="..."
              items-per-page="20"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat width="90%">
                  <v-toolbar-title>Cinco e seis</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:[`item.no_nome`]="{ item }">
                <div class="futurabold">{{ item.no_nome.toUpperCase() }}</div>
              </template>
              <template v-slot:[`item.ts_chamada`]="{ item }">
                <div>{{ item.ts_chamada | moment("HH:mm") }}</div>
              </template>
              <template v-slot:[`item.tempoAguardando`]="{ item }">
                <div>{{ item.ts_chamada | moment("diff", "minutes") | replaceMinus }}</div>
              </template>
              <template v-slot:no-data> ... </template>
            </v-data-table>
          </v-col>
          <v-col class="red" style="padding: 4px">
            <v-data-table
              :headers="headers"
              :items="criancasSete"
              item-key="id"
              sort-by="dataChamada"
              class="elevation-1"
              :loading="loading"
              loading-text="..."
              items-per-page="20"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat width="90%">
                  <v-toolbar-title>Sete e oito</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:[`item.no_nome`]="{ item }">
                <div class="futurabold">{{ item.no_nome.toUpperCase() }}</div>
              </template>
              <template v-slot:[`item.ts_chamada`]="{ item }">
                <div>{{ item.ts_chamada | moment("HH:mm") }}</div>
              </template>
              <template v-slot:[`item.tempoAguardando`]="{ item }">
                <div>
                  {{ item.ts_chamada | moment("diff", "minutes") | replaceMinus }}
                </div>
              </template>
              <template v-slot:no-data> ... </template>
            </v-data-table>
          </v-col>
          <v-col class="mr-n4 orange" style="padding: 4px">
            <v-data-table
              :headers="headers"
              :items="criancasNove"
              item-key="id"
              sort-by="dataChamada"
              class="elevation-1"
              :loading="loading"
              loading-text="..."
              items-per-page="20"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat width="90%">
                  <v-toolbar-title>Nove, dez e onze</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:[`item.no_nome`]="{ item }">
                <div class="futurabold">{{ item.no_nome.toUpperCase() }}</div>
              </template>
              <template v-slot:[`item.ts_chamada`]="{ item }">
                <div>{{ item.ts_chamada | moment("HH:mm") }}</div>
              </template>
              <template v-slot:[`item.tempoAguardando`]="{ item }">
                <div>{{ item.ts_chamada | moment("diff", "minutes") | replaceMinus }}</div>
              </template>
              <template v-slot:no-data> ... </template>
            </v-data-table>
          </v-col-->
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headersTodos"
              :items="desserts"
              item-key="id"
              sort-by="dataChamada"
              class="elevation-1"
              :item-class="itemRowBackground"
              :loading="loading"
              loading-text="Carregando..."
              items-per-page=20
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Chamada todos</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:[`item.no_nome`]="{ item }">
                <div class="futurabold">{{ item.no_nome.toUpperCase() }}</div>
              </template>
              <template v-slot:[`item.nu_idade`]="{ item }">
                <div class="futurabold" align="right">{{ item.nu_idade }}</div>
              </template>
              <template v-slot:[`item.ts_chamada`]="{ item }">
                <div align="center">{{ item.ts_chamada | moment("HH:mm") }}</div>
              </template>
              <template v-slot:[`item.ic_grupo`]="{ item }">
                <div align="center">{{ item.ic_grupo != null ? item.ic_grupo.data == '1' ? 'GRUPO' : '' : ''  }}</div>
              </template>
              <template v-slot:[`item.tempoAguardando`]="{ item }">
                <div align="center">{{ item.ts_chamada | moment("diff", "minutes") | replaceMinus }}</div>
              </template>
              <template v-slot:no-data> ... </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  getListaChamada,
} from "../services/cadastroCriancas.js";

export default {
  data: () => ({
    loading: false,
    atualizarAutomaticamente: false,
    timer: "",
    headers: [
      //{ text: "#", value: "rowNumber" },
      //{ text: "Código", value: "co_codigo" },
      //{ text: "Hora", value: "ts_chamada", width: "20%" },
      { text: "Tempo", value: "tempoAguardando", width: "50px" },
      { text: "Nome", value: "no_nome" },
    ],
    headersTodos: [
      //{ text: "#", value: "rowNumber" },
      { text: "Código", value: "co_codigo" },
      { text: "Hora da Chamada", value: "ts_chamada" },
      { text: "Tempo aguardando", value: "tempoAguardando" },
      { text: "Idade", value: "nu_idade" },
      { text: "Grupo", value: "ic_grupo" },
      { text: "Nome", value: "no_nome" },
    ],
    desserts: [],
    criancasCinco: [],
    criancasSete: [],
    criancasNove: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  filters: {
    replaceMinus(value) {
      return value.toString().replace("-", "");
    },
  },

  components: {},
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo" : "Editar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.timer = setInterval(() => {
      if (this.atualizarAutomaticamente) this.initialize();
    }, 5000);
  },

  methods: {
    async initialize() {
      this.loading = true;
      /*
      getListaCriancas().then((res) => {
        const filteredRows = res.data.filter(
          (row) => row["situacao"] == "chamando"
        );
        this.desserts = filteredRows;
        this.loading = false;
      });
      */
      getListaChamada().then((res) => {
        this.desserts = res.data;
        /*this.criancasCinco = res.data.filter(
          (row) => row["nu_idade"] == 5 || row["nu_idade"] == 6
        );
        this.criancasSete = res.data.filter(
          (row) => row["nu_idade"] == 7 || row["nu_idade"] == 8
        );
        this.criancasNove = res.data.filter(
          (row) =>
            row["nu_idade"] == 9 ||
            row["nu_idade"] == 10 ||
            row["nu_idade"] == 11 ||
            row["nu_idade"] == 12 ||
            row["nu_idade"] == 13
        );*/

        this.loading = false;
      });
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

    beforeDestroy() {
      this.cancelAutoUpdate();
    },
    itemRowBackground: function (item) {
     return item.ic_grupo != null && item.ic_grupo.data == '1' ? 'style-grupo' : ''
  }
  },
};
</script>

<style>
.style-grupo {
  background-color: rgb(215,215,44)
}
</style>
