import Vue from "vue";
import {
  login,
  getToken,
  getUsuario,
  logoff
} from '../../services/autenticacao'

import {
  getUnidade
} from '../../services/unidades'

export default {
  state: {
    usuario: {},
    loading: false,
    loginDropdown: false,
    token: ""
  },
  getters: {
    logado: state => ((state.usuario || {}).matricula ? true : false),
    usuario: state => state.usuario,
    loading: state => state.loading
  },
  actions: {
    autoLogin: ({
      commit,
      dispatch
    }) => {
      const token = getToken();
      if (!token) return false;
      const usuario = getUsuario();
      const unidade = getUnidade();

      dispatch("unidades/setUnidade", unidade || usuario.unidade, {
        root: true
      });
      commit("SET_LOADING", true);
      commit("SET_USUARIO", usuario);
      commit("SET_TOKEN", token);
      commit("SET_LOADING", false);
    },
    toogleLoginDropdown: ({
      commit,
      state
    }, value) => {
      if (value === null) value = !state.loginDropdown;
      commit("SET_DROPDOWN", value);
    },
    login: async ({
      commit,
      dispatch
    }, payload) => {
      commit("SET_LOADING", true);
      const usuario = await login(payload)
      const unidade = getUnidade();
      dispatch('unidades/setUnidade', unidade || usuario.unidade, {
        root: true
      })
      commit('SET_USUARIO', usuario);
      commit('SET_TOKEN', usuario.token)
      commit("SET_LOADING", false);
    },
    logoff: ({
      commit
    }) => {
      commit("SET_LOADING", true);
      commit("SET_USUARIO", {});
      commit("SET_TOKEN", "");
      Vue.$storage.remove("usuario");
      Vue.$storage.remove("token");
      commit("SET_LOADING", false);
      logoff();
    },
    loading: ({
      commit
    }, payload) => {
      commit("SET_LOADING", payload);
    }
  },
  mutations: {
    SET_DROPDOWN: (state, payload) => (state.loginDropdown = Object.freeze(payload)),
    SET_USUARIO: (state, payload) => (state.usuario = Object.freeze(payload)),
    SET_TOKEN: (state, payload) => (state.token = payload),
    SET_LOADING: (state, payload) => (state.loading = payload)
  }
};