import { fetch } from "../helpers/rest";

import State from "../helpers/state";

const state = new State();

export const get = () => {
  return fetch("unidade", 12 * 60);
};

export const getUnidade = () => state.get("unidadeUsuario");
