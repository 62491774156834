<template>
  <div class="home">
    <RegistrarChegadaResponsavel msg="Bem vindo a EBF"/>
  </div>
</template>

<script>
// @ is an alias to /src
import RegistrarChegadaResponsavel from '@/components/RegistrarChegadaResponsavel.vue'

export default {
  name: 'RegistrarChegadaResponsaveView',
  components: {
    RegistrarChegadaResponsavel
  }
}
</script>
