import Vue from 'vue'
export default {
  state: {
    resultado: [],
    loading: false
  },
  actions: {
    get: ({
      commit
    }, payload) => {
      commit("SET_RESULTADO_PESQUISA", []);
      commit("SET_LOADING_PESQUISA", true);
      const chave = `pesquisa_${ payload.tipo ? payload.tipo + '_' : ""}${payload.valor}`
      if (Vue.$storage.has(chave)) {
        const resultado = Vue.$storage.get(chave);
        commit("SET_RESULTADO_PESQUISA", resultado)
        commit("SET_LOADING_PESQUISA", false);
      } else {

        Vue.$storage.set(chave, [], {
          ttl: 100 * 60 * 60 * 6
        })
        commit("SET_LOADING_PESQUISA", false);
      }
    }
  },
  mutations: {
    SET_RESULTADO_PESQUISA: (state, payload) => (state.resultado = payload),
    SET_LOADING_PESQUISA: (state, payload) => (state.loading = payload)
  },
  getters: {
    resultado: state =>
      state.resultado.map(o => {
        o.operacao = ("0000" + o.operacao).slice(-4) + " " + o.nomeOperacao;
        return o;
      }),
    loading: state => state.loading
  }
}