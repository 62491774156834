// ttl informed in mninutes. default value is 60 minutes

//import State from './state'

//const state = new State()

export const fetch = (url, ttl = 5, headers = {'Cache-Control': 'no-cache'}) => {
  //const key = url.replace(/\//g, "_");
  
  /*
  try {
    if (state.has(key)) {
      return Promise.resolve(state.get(key));
    }
    return window.axios.get(url, headers).then(({
      data
    }) => {
      //state.set(key, data, ttl * 1000 * 60);
      ttl += ttl;
      return data;
    });
  } catch (error) {
    return Promise.reject(error);
  }*/
  return window.axios.get(url, headers).then(({
    data
  }) => {
    //console.log(key);
    ttl += ttl;
    return data;
  });
};

export const post = (url, body) => {
  return window.axios
    .post(url, body)
    .then(data => data)
    .catch(error => Promise.reject(error));
};

export const del = url => {
  return window.axios
    .delete(url)
    .then(data => data)
    .catch(error => Promise.reject(error));
};

export const patch = (url, body) => {
  return window.axios
    .patch(url, body)
    .then(data => data)
    .catch(error => {Promise.reject(error); console.log(error);});
};