import {
  get as getProdutos
} from '../../services/produtos';
export default {
  state: {
    lista: [],
    loading: false,
    error: false,
    mascaras: []
  },
  actions: {
    get: async ({
      commit
    }) => {
      commit("SET_LISTA_PRODUTOS", []);
      commit("SET_LOADING_PRODUTOS", true);
      getProdutos().then(data => {
        commit("SET_LISTA_PRODUTOS", data);
        commit("SET_LOADING_PRODUTOS", false);
      })
    },
  },
  mutations: {
    SET_LISTA_PRODUTOS: (state, payload) => (state.lista = Object.freeze(payload)),
    SET_LOADING_PRODUTOS: (state, payload) => (state.loading = payload)
  },
  getters: {
    lista: state => state.lista,
  }
};