export default {
  namespaced: true,
  state: {
    dados: {},
    filtro: {}
  },
  mutations: {
    SET_DADOS(state, payload) {
      state.dados = payload;
    },
    SET_FILTRO(state, payload) {
      state.filtro = payload;
      console.log("state.filtro");
      console.log(state.filtro);
    }
  },
  actions: {
    setDados({ commit }, value) {
      commit("SET_DADOS", value);
    },
    setFiltro({ commit }, value) {
      commit("SET_FILTRO", value);
    }
  }
};
