<template>
  <div class="home">
    <AdminCheckin msg="Bem vindo a EBF"/>
  </div>
</template>

<script>
// @ is an alias to /src
import AdminCheckin from '@/components/AdminCheckin.vue'

export default {
  name: 'AdminCheckinView',
  components: {
    AdminCheckin
  }
}
</script>
