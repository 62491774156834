import { render, staticRenderFns } from "./ModeloQrCodes.vue?vue&type=template&id=8c3d8248&scoped=true&"
import script from "./ModeloQrCodes.vue?vue&type=script&lang=js&"
export * from "./ModeloQrCodes.vue?vue&type=script&lang=js&"
import style0 from "./ModeloQrCodes.vue?vue&type=style&index=0&id=8c3d8248&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c3d8248",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
