import { fetch, patch,  } from "../helpers/rest";

export default {};

export function getListaCriancas() {
  return fetch(`cadastroCriancas/lista`);
}

export function getListaChamada() {
  return fetch(`cadastroCriancas/chamada`);
}

export function getCrianca(codigo) {
  return fetch(`cadastroCriancas/${codigo}`);
}

export function setCrianca(id, item) {
  return patch(`cadastroCriancas/update/${id}`, item);
}

export function setRegistro(id, acao, codigo) {
  return patch(`cadastroCriancas/${id}/${acao}/${codigo}`);
}
